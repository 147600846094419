<template>
  <edit-dialog
    :title="title"
    :is-saving="loading"
    :has-secondary="!isCreating"
    :secondary-text="discount.status ? 'Disable' : 'Enable'"
    :is-disabled="!$can('pub_manage_discounts')"
    class="p-0"
    @save="save"
    @secondary="$emit(discount.status ? 'disable' : 'enable')"
    @close="$emit('close')"
  >
    <template v-if="isCreating">
      <div class="p-8">
        <discount-settings
          ref="settings"
          :discount="discount"
          @change="changes = $event"
        />

        <el-alert
          v-if="errors"
          type="error"
          class="margin-top"
          :title="errors"
        />
      </div>
    </template>
    <template v-else>
      <el-tabs
        type="border-card"
        :value="showTab"
        stretch
        class="tab-buttons"
        @change="$emit('tab', $event)"
      >
        <el-tab-pane label="Settings" name="settings">
          <discount-settings
            ref="settings"
            :discount="discount"
            @change="changes = $event"
          />

          <el-alert
            v-if="errors"
            type="error"
            class="margin-top"
            :title="errors"
          />
        </el-tab-pane>

        <el-tab-pane v-if="discount.history" label="History" name="history">
          <discount-history :history="discount.history" />
        </el-tab-pane>
      </el-tabs>
    </template>
  </edit-dialog>
</template>

<script>
import { extend } from 'lodash';

import EditDialog from '@/components/Core/EditDialog';
import DiscountHistory from '@/components/Supplier/Discounts/DiscountHistory';
import DiscountSettings from '@/components/Supplier/Discounts/DiscountSettings';

export default {
  components: {
    DiscountHistory,
    DiscountSettings,
    EditDialog
  },
  props: {
    discount: {
      type: Object,
      required: true
    },
    loading: Boolean,
    errors: { type: [String, Boolean], default: null },
    tab: { type: String, default: null }
  },

  data() {
    return {
      activeTab: 'settings',
      changes: false,
      isValid: true
    };
  },

  computed: {
    title() {
      return this.isCreating ? 'Create Discount' : 'Edit Discount';
    },

    showTab() {
      return this.tab || this.activeTab;
    },

    isCreating() {
      return !this.discount.id;
    }
  },

  methods: {
    change(changes) {
      this.changes = extend(this.changes || {}, changes);
    },

    async save() {
      if (this.changes || this.isCreating) {
        await this.$refs.settings.validate(async isValid => {
          if (isValid) {
            this.$emit('save', this.changes);
          } else {
            this.$message.error(
              'Please fix any errors and try submitting again.'
            );
          }
        });
      } else {
        this.$emit('close');
      }
    }
  }
};
</script>
