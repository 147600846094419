<template>
  <el-form
    ref="form"
    :model="editable"
    :rules="rules"
    class="discount-settings text-dark-gray"
    :disabled="!$can('pub_manage_discounts')"
  >
    <custom-el-form-item
      label="Discount Name"
      tooltip="This name won't be displayed to your buyers"
      label-class="font-semibold"
      prop="name"
      :disabled="!$can('pub_manage_discounts')"
    >
      <el-input
        v-model="editable.name"
        placeholder="Name your discount"
        class="margin-top-xs"
        @change="change"
      />
    </custom-el-form-item>

    <custom-el-form-item
      label="Discount Code"
      label-class="font-semibold"
      prop="code"
      class="margin-top-xl"
    >
      <el-input
        v-model="editable.code"
        v-mask.uppercase.alphanumeric
        placeholder="Enter Code"
        class="margin-top-xs"
        @change="change"
      />
    </custom-el-form-item>

    <custom-el-form-item
      label="Discount Value"
      label-class="font-semibold"
      class="margin-top-xl"
      no-click-focus
    >
      <div class="margin-top-xs">
        <div class="flex">
          <div class="block p-3 flex-1">
            <el-select v-model="editable.type" class="w-full" @change="change">
              <el-option
                v-for="type in DiscountTypes"
                :key="`type-${type.value}`"
                :label="type.label"
                :value="type.name"
              />
            </el-select>
          </div>
          <div class="block py-3 pr-3 pl-12 flex-1">
            <el-form-item prop="value">
              <el-input
                v-model="editable.value"
                v-mask.number
                placeholder="0.00"
                @change="change"
              >
                <icon v-if="isPercent" slot="append" :icon="percent" />
                <icon v-else slot="prepend" :icon="dollar" />
              </el-input>
            </el-form-item>
          </div>
        </div>
      </div>
    </custom-el-form-item>

    <div class="margin-top-xl">
      <div class="flex">
        <div class="block p-3 w-1/3">
          <custom-el-form-item
            label="Start Date"
            label-class="font-semibold"
            prop="start_date"
          >
            <el-date-picker
              v-model="editable.start_date"
              placeholder="Start Date"
              format="MM/dd/yyyy"
              class="margin-top-xs"
              @change="change"
            />
          </custom-el-form-item>
        </div>
        <div class="block p-3 w-1/3">
          <custom-el-form-item
            label="End Date"
            label-class="font-semibold"
            prop="end_date"
          >
            <el-date-picker
              v-model="editable.end_date"
              clearable
              placeholder="Never Expires"
              format="MM/dd/yyyy"
              class="margin-top-xs"
              :picker-options="{ disabledDate: disabledEndDate }"
              @change="change"
            />
          </custom-el-form-item>
        </div>
        <div class="block p-3 w-1/3">
          <custom-el-form-item
            label="Number of Uses Allowed"
            label-class="font-semibold"
            prop="uses"
          >
            <el-input
              v-mask.integer
              :value="editable.uses || ''"
              clearable
              placeholder="Unlimited"
              class="margin-top-xs"
              @input="editable.uses = $event"
              @change="change"
            />
            <div class="discount-used">
              Used {{ discount.used | integer }}
              {{ discount.used !== 1 ? 'times' : 'time' }}
            </div>
          </custom-el-form-item>
        </div>
      </div>
    </div>

    <custom-el-form-item
      label="Notes"
      label-class="font-semibold"
      prop="notes"
      class="margin-top-xxl"
    >
      <el-input
        v-model="editable.notes"
        type="textarea"
        placeholder="Enter Notes"
        class="margin-top-xs"
        rows="5"
        @change="change"
      />
    </custom-el-form-item>
  </el-form>
</template>

<script>
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { datetimeDb } from '@/utils/filters';

import CustomElFormItem from '@/components/Custom/CustomElFormItem';
import { DiscountType, DiscountTypes } from '@/constants';

import { dollar, percent } from '@/vendor/icons';

export default {
  components: {
    CustomElFormItem
  },
  props: {
    discount: {
      type: Object,
      required: true
    }
  },

  data() {
    let editable = {
      name: '',
      code: '',
      type: DiscountType.PERCENT,
      value: null,
      start_date: moment(),
      end_date: null,
      uses: '',
      status: true,
      notes: '',
      used: 0,
      updated_at: datetimeDb(moment()),
      history: [],

      ...cloneDeep(this.discount)
    };

    return {
      DiscountTypes,
      editable,
      rules: {
        name: [
          { required: true, message: 'Please provide a name', trigger: 'blur' },
          {
            message: 'Must be less than 60 characters',
            max: 60,
            trigger: 'change'
          }
        ],
        code: [
          {
            required: true,
            message: 'Please provide a discount code',
            trigger: 'blur'
          },
          {
            message: 'The discount code must be between 3 and 20 characters!',
            min: 3,
            max: 20,
            trigger: 'change'
          }
        ],
        value: [
          {
            required: true,
            message: 'The amount must be greater than 0',
            min: 1,
            trigger: 'change'
          }
        ],
        start_date: [
          {
            required: true,
            message: 'You must choose a Start Date',
            trigger: 'change'
          }
        ]
      },

      // Icons
      dollar,
      percent
    };
  },

  computed: {
    isPercent() {
      return this.editable.type === DiscountType.PERCENT;
    }
  },

  methods: {
    disabledEndDate(date) {
      return moment(date).isBefore(this.editable.start_date);
    },

    change() {
      this.editable.start_date = datetimeDb(
        moment(this.editable.start_date).startOf('day')
      );
      this.editable.end_date = datetimeDb(
        moment(this.editable.end_date).endOf('day')
      );
      this.$emit('change', this.editable);
    },

    validate(callback) {
      return this.$refs.form.validate(callback);
    }
  }
};
</script>
