<template>
  <div class="enabled-disabled-list">
    <transition-group
      tag="div"
      :name="transitionName"
      class="relative animate-position"
      :class="groupClass"
    >
      <h4
        v-if="disabled.length > 0"
        key="enabled-header"
        class="pad-bottom-xl w-full pad-left-sm"
      >
        <slot name="enabled-text">Enabled</slot>
      </h4>

      <div
        v-for="(item, index) in enabled"
        :key="`edl-item-${item.id}`"
        :class="enabledColumnClass(index)"
      >
        <slot name="enabled" :item="item">
          <slot name="card" :item="item" />
        </slot>
      </div>

      <div
        v-if="disabled.length > 0"
        key="break-disabled"
        class="pad-xxl w-full"
      ></div>

      <h4
        v-if="disabled.length > 0"
        key="heading-disabled"
        class="pad-bottom-xl w-full pad-left-sm"
      >
        <slot name="disabled-text">Disabled</slot>
      </h4>

      <div
        v-for="(item, index) in disabled"
        :key="`edl-item-${item.id}`"
        :class="disabledColumnClass(index)"
      >
        <slot name="disabled" :item="item">
          <slot name="card" :item="item" />
        </slot>
      </div>
    </transition-group>
  </div>
</template>

<script>
import { filter } from 'lodash';

export default {
  name: 'EnabledDisabledList',
  props: {
    items: { type: Array, default: () => [] },
    transitionName: {
      type: String,
      default: 'fadeLeft'
    },
    enabledKey: {
      type: String,
      default: 'is_enabled'
    },
    columnClass: {
      type: String,
      default: 'column'
    },
    groupClass: {
      type: String,
      default: 'columns is-multiline is-vcenter is-stretch'
    }
  },

  computed: {
    enabled() {
      return filter(this.items, { [this.enabledKey]: true });
    },

    disabled() {
      return filter(this.items, { [this.enabledKey]: false });
    }
  },

  methods: {
    enabledColumnClass(index) {
      return (
        this.columnClass +
        (index === this.enabled.length - 1 ? ' border-bottom border-2' : '')
      );
    },

    disabledColumnClass(index) {
      return (
        this.columnClass +
        (index === this.disabled.length - 1 ? ' border-bottom border-2' : '')
      );
    }
  }
};
</script>
