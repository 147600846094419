<template>
  <div class="discount-history">
    <el-table
      :data="history"
      border
      class="el-table-slim"
      :default-sort="{ prop: 'created_at', order: 'descending' }"
    >
      <el-table-column label="Date" sortable prop="created_at" width="170">
        <template slot-scope="{ row }"
          >{{ row.created_at | datetime('MM/DD/Y h:mm a') }}
        </template>
      </el-table-column>

      <el-table-column label="Message" prop="message">
        <template slot-scope="{ row }">
          <div class="whitespace-pre-wrap">{{ row.message }}</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    history: {
      type: Array,
      required: true
    }
  }
};
</script>
