<template>
  <div class="discount block-item-list relative">
    <div class="flex items-center">
      <div class="block p-0 flex-1" :class="{ 'is-low-opacity': isDisabled }">
        <div class="pad-left-sm">
          <a
            class="font-bold name text-dark-gray hover:text-blue"
            @click="$emit('edit')"
          >
            {{ discount.name }}
          </a>
          <ul class="discs font-size-sm margin-top-sm">
            <li>{{ discount.code }}</li>
            <li>
              <template v-if="isPercent">
                {{ discount.value | number }}% off
              </template>
              <template v-else> {{ discount.value | currency }} off</template>
            </li>
            <li>
              Valid: {{ discount.start_date | date }}
              <template v-if="discount.end_date">
                to {{ discount.end_date | date }}
              </template>
              <template v-else>
                ; Never expires
              </template>
            </li>
            <li>
              <template v-if="discount.uses">
                <div :class="{ 'text-yellow': hasMaxUses }">
                  <icon v-if="hasMaxUses" :icon="exclamationCircle" />
                  Uses: {{ discount.used }} / {{ discount.uses }}
                </div>
              </template>
              <template v-else>
                Uses: {{ discount.used }} /
                <icon :icon="infinite" />
              </template>
            </li>
            <li v-if="discount.notes">{{ discount.notes }}</li>
          </ul>
          <tooltip-icon
            class="remove absolute top-0 left-0"
            :is-disabled="!$can('pub_manage_discounts')"
            :icon="trashEmpty"
            icon-class="icon-md text-dark-gray cursor-pointer hover:text-red"
            @click="isDeleting = true"
          />
        </div>
      </div>
      <div v-if="isDisabled" class="block text-right">
        <div class="margin-right-xxl">
          <el-button class="button-white-blue w-40" @click="$emit('enable')">
            Enable
          </el-button>
        </div>
      </div>
    </div>

    <confirm-dialog
      v-if="isDeleting"
      title="Delete Discount"
      cancel-text="Never mind"
      confirm-class="button-red"
      confirm-text="Delete"
      class="text-center"
      @confirm="$emit('delete') && (isDeleting = false)"
      @close="isDeleting = false"
    >
      <div>Are you sure you want to remove this discount code?</div>

      <div class="margin-top">
        This discount code will not be removed from any promotions that have
        already been booked.
      </div>
    </confirm-dialog>

    <loading-page v-if="isLoading" class="is-low-opacity" />
  </div>
</template>

<script>
import { DiscountType } from '@/constants';
import { exclamationCircle, infinite, trashEmpty } from '@/vendor/icons';

import ConfirmDialog from '@/components/Core/ConfirmDialog';
import LoadingPage from '@/components/Core/Loading/LoadingPage';
import TooltipIcon from '@/components/Core/TooltipIcon';

export default {
  components: {
    ConfirmDialog,
    LoadingPage,
    TooltipIcon
  },
  props: {
    discount: {
      type: Object,
      required: true
    },
    isDisabled: Boolean,
    isLoading: Boolean
  },

  data() {
    return {
      isDeleting: false,

      // Icons
      exclamationCircle,
      infinite,
      trashEmpty
    };
  },

  computed: {
    hasMaxUses() {
      if (this.discount.uses > 0) {
        return this.discount.used >= this.discount.uses;
      } else {
        return false;
      }
    },

    isPercent() {
      return DiscountType.PERCENT === this.discount.type;
    }
  }
};
</script>

<style lang="scss" scoped>
.discount {
  padding-left: 2.3em;
}
</style>
