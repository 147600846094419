<template>
  <div id="page-discounts" class="page-content">
    <the-page-header>
      <template slot="title">
        Discounts
      </template>
      <template slot="subtitle">
        Discounts are only available to your customers booking through your Ad
        Shop. Create and share discount codes at will.
      </template>
    </the-page-header>

    <div class="margin-top-xxl">
      <el-button
        class="button-blue"
        :disabled="!$can('pub_manage_discounts')"
        @click="discountToEdit = {}"
      >
        Create Discount
      </el-button>
    </div>

    <discounts-query class="pad-top-xxl">
      <template slot-scope="{ isLoading, data: supplier }">
        <discount-mutation @done="mutationDone" @error="errors = $event || ''">
          <template
            slot-scope="{
              isSaving,
              createDiscount,
              updateDiscount,
              deleteDiscount
            }"
          >
            <div class="relative">
              <enabled-disabled-list
                v-if="supplier"
                :items="orderBy(supplier.discounts, 'updated_at', 'desc')"
                enabled-key="status"
                group-class="block w-full"
                column-class="block relative w-full py-6 border-top border-2"
              >
                <template slot="enabled" slot-scope="{ item: discount }">
                  <discount
                    :discount="discount"
                    :is-loading="discountToLoad.id === discount.id"
                    @edit="discountToEdit = discount"
                    @delete="deleteDiscount(discount)"
                  />
                </template>

                <template slot="disabled" slot-scope="{ item: discount }">
                  <discount
                    :discount="discount"
                    is-disabled
                    @edit="discountToEdit = discount"
                    @delete="deleteDiscount(discount)"
                    @enable="
                      save(createDiscount, updateDiscount, discount, {
                        status: true
                      })
                    "
                  />
                </template>
              </enabled-disabled-list>

              <loading-page v-else :failed="!isLoading" />
            </div>

            <discount-dialog
              v-if="discountToEdit"
              :discount="discountToEdit"
              :loading="isSaving"
              :errors="errors"
              @save="
                save(createDiscount, updateDiscount, discountToEdit, $event)
              "
              @enable="
                save(createDiscount, updateDiscount, discountToEdit, {
                  status: true
                })
              "
              @disable="
                save(createDiscount, updateDiscount, discountToEdit, {
                  status: false
                })
              "
              @close="discountToEdit = false"
            />
          </template>
        </discount-mutation>
      </template>
    </discounts-query>
  </div>
</template>

<script>
import { orderBy } from 'lodash';

import Discount from './Discount';
import DiscountDialog from './DiscountDialog';
import EnabledDisabledList from '@/components/Core/EnabledDisabledList';
import LoadingPage from '@/components/Core/Loading/LoadingPage';
import ThePageHeader from '@/components/Core/Layout/ThePageHeader';
import { DiscountMutation } from '@/components/Mutations';
import { DiscountsQuery } from '@/components/Queries';

export default {
  components: {
    Discount,
    DiscountDialog,
    DiscountMutation,
    DiscountsQuery,
    EnabledDisabledList,
    LoadingPage,
    ThePageHeader
  },

  data() {
    return {
      errors: '',
      discountToEdit: false,
      discountToLoad: false
    };
  },

  methods: {
    orderBy,

    mutationDone() {
      this.discountToEdit = false;
      this.discountToLoad = false;
    },

    save(create, update, original, changes) {
      this.errors = false;

      if (original.id) {
        update(original, changes);
        // We are using optimistic responses for updates,
        // so lets hide it immediately and assume everything went according to plan... what?
        this.discountToLoad = this.discountToEdit;
        this.discountToEdit = false;
      } else {
        create(changes);
      }
    }
  }
};
</script>
